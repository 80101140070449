import { ReactElement } from 'react';

import Icon, { IconProps } from '@components/atoms/Icon';

const CryoTutor4 = ({ size, ...rest }: IconProps): ReactElement => (
    <Icon size={size} viewBox="0 0 70 80" {...rest}>
        <g>
            <path
                d="M43.1,62.5H26.9c-1,0-1.9,0.8-1.9,1.9v1.2c0,1,0.8,1.9,1.9,1.9h16.2c1,0,1.9-0.8,1.9-1.9v-1.2C45,63.3,44.2,62.5,43.1,62.5
		z"
            />
            <path
                d="M62.5,0h-55C3.4,0,0,3.4,0,7.5v65C0,76.6,3.4,80,7.5,80h55c4.1,0,7.5-3.4,7.5-7.5v-65C70,3.4,66.6,0,62.5,0z M62.5,71.6
		c0,0.5-0.4,0.9-0.9,0.9H8.4c-0.5,0-0.9-0.4-0.9-0.9V8.4c0-0.5,0.4-0.9,0.9-0.9h53.1c0.5,0,0.9,0.4,0.9,0.9V71.6z"
            />
            <path
                d="M49.3,39.7c0-0.1,0-0.1,0-0.2c0.2-0.7,0.3-1.5,0.4-2.3c0-0.3,0-0.7,0.1-1.1c0-0.1,0-0.2,0-0.3c0-3.5-1.2-6.7-3.2-9.2
		c0,0,0,0,0,0c-0.2-0.2-0.4-0.5-0.6-0.7c0,0,0,0,0,0c-1.2-1.4-2.7-2.5-4.3-3.3c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1-0.1
		c-0.1,0-0.2-0.1-0.3-0.1c0,0-0.1,0-0.1,0c-0.3-0.1-0.5-0.2-0.8-0.3c-0.2-0.1-0.4-0.2-0.7-0.3c-0.1,0-0.3-0.1-0.4-0.1
		c-0.4-0.1-0.7-0.2-1.1-0.3c-0.2,0-0.4-0.1-0.6-0.1c-0.3-0.1-0.6-0.1-0.9-0.2c-0.4,0-0.8-0.1-1.2-0.1c-0.1,0-0.3,0-0.4,0
		c-0.5,0-1,0-1.5,0.1c-0.2,0-0.3,0-0.5,0.1c-0.3,0-0.6,0.1-0.9,0.1c-0.2,0-0.4,0.1-0.6,0.1c-0.3,0.1-0.5,0.1-0.8,0.2
		c-0.2,0.1-0.4,0.1-0.6,0.2c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.4,0.2-0.7,0.4
		c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.5,0.3-0.7,0.5c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.2-0.5,0.4-0.7,0.6c-0.1,0.1-0.1,0.1-0.2,0.2
		c-2.8,2.5-4.6,6-5,9.9c0,0.4-0.1,0.8-0.1,1.2c0,0,0,0,0,0v0v0c0,0,0,0,0,0c0,0.3,0,0.6,0,0.8c0,0.2,0,0.4,0,0.7c0,0,0,0,0,0
		c0,0.1,0,0.2,0,0.2c0,0.4,0.1,0.8,0.2,1.2c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0.4,0.2,0.8,0.3,1.2c0,0.1,0.1,0.2,0.1,0.4
		c0.1,0.3,0.2,0.6,0.3,0.9c0,0.1,0.1,0.2,0.1,0.3c0.2,0.4,0.3,0.7,0.5,1.1c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.2,0.3,0.5,0.4,0.7
		c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.2,0.3,0.4,0.4,0.6c0.2,0.3,0.4,0.5,0.7,0.8c0.1,0.1,0.2,0.3,0.4,0.4c0.2,0.2,0.4,0.4,0.5,0.5
		c0.1,0.1,0.2,0.2,0.3,0.3c0.7,0.6,1.5,1.2,2.3,1.7c0,0,0,0,0.1,0c1.6,0.9,3.4,1.6,5.3,1.9c0.2,0,0.3,0.1,0.5,0.1
		c0.2,0,0.4,0,0.5,0.1c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0c0.3,0,0.6,0,0.8,0c0,0,0,0,0,0h0h0c0,0,0,0,0,0c0.4,0,0.8,0,1.3-0.1
		c0.2,0,0.4,0,0.6-0.1c0.1,0,0.3,0,0.4-0.1c4.5-0.7,8.3-3.3,10.5-7c0,0,0-0.1,0.1-0.1C48.4,42.3,48.9,41.1,49.3,39.7z"
            />
        </g>
    </Icon>
);

export default CryoTutor4;
